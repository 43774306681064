<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container style="width: 90%; max-height: 90vh;" class="mx-auto">
        <v-row>
          <v-card outlined class="font-weight-bold pa-2" style="width: 100%;">
            {{ $t('title.contractor.siteUsageGuide') }}
          </v-card>
        </v-row>
        <div class="pl-4 pr-4">
          <v-row>
            <p>{{ $t('description.siteUsageGuides.explanation1') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.siteUsageGuides.explanation2') }}</p>
          </v-row>
        </div>
        <v-row>
          <v-card outlined class="font-weight-bold pa-2" style="width: 100%;">
            {{ $t('title.contractor.applicationConfirmationMethod') }}
          </v-card>
        </v-row>
        <div class="pl-4 pr-4">
          <v-row>
            <p>{{ $t('description.applicationConfirmationMethods.explanation1') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.applicationConfirmationMethods.explanation2') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.applicationConfirmationMethods.explanation3') }}</p>
          </v-row>
        </div>
        <div v-if="comment.length > 0">
          <v-row>
            <v-card outlined class="font-weight-bold pa-2" style="width: 100%;">
              {{ $t('label.agencyComment') }}
            </v-card>
          </v-row>
          <v-row class="pl-4 pr-4">
            <v-col class="ma-0 pa-0">
              <p
                v-for="(message, index) in comment"
                :key="index"
                style="word-wrap: break-word"
                class="ma-0 pa-0"
              >
                {{ message }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div v-if="extraFiles.length > 0 || notImageFiles.length > 0">
          <v-row class="pt-4">
            <v-card outlined class="font-weight-bold pa-2" style="width: 100%;">
              {{ $t('label.referenceMaterial') }}
            </v-card>
          </v-row>
          <v-row class="pl-4 pr-4">
            <v-col v-for="file in extraFiles" :key="file.src">
              <v-container class="pa-0" style="max-width: 400px;">
                <v-row>
                  <p class="float-left mr-3 my-1 pa-0">●{{ file.name }}</p>
                </v-row>
                <v-row>
                  <v-layout
                    justify-center
                    style="width: 400px; height: 300px; max-width:90vw; border: #ddd 3px solid;"
                  >
                    <v-img
                      max-width="100%"
                      max-height="100%"
                      :contain="true"
                      :src="file.src"
                      class="mx-auto my-auto"
                      style="display:block;"
                    />
                  </v-layout>
                </v-row>
                <v-row class="mt-2">
                  <v-btn
                    class="font-weight-black"
                    align="center"
                    :href="file.src"
                    width="35vw"
                    max-width="170px"
                    color="download"
                    :download="file.name"
                    style="font-size:14px"
                    dark
                    rounded
                  >
                    {{ $t('button.downloadExtraFile') }}
                  </v-btn>
                </v-row>
                <v-row>
                  <v-container
                    v-if="file.comment"
                    id="scroll-target"
                    class="overflow-y-auto mx-auto mt-2"
                    style="height: 100px; width: 400px; max-width:100%;  border: #000 1px solid; "
                    wrap
                  >
                    <v-row
                      v-for="(message, index) in file.comment"
                      :key="index"
                      class="l-3 my-0 mx-auto"
                      style="max-width:400px;"
                    >
                      {{ message }}
                    </v-row>
                  </v-container>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row class="pl-4 pr-4">
            <v-col v-for="file in notImageFiles" :key="file.id">
              <v-container class="pa-0" style="max-width: 400px;">
                <v-row>
                  <p class="float-left mr-3 my-1 pa-0">●{{ file.name }}</p>
                </v-row>
                <v-row>
                  <v-btn
                    class="font-weight-black"
                    align="center"
                    width="35vw"
                    max-width="170px"
                    color="download"
                    style="font-size:14px"
                    dark
                    rounded
                    @click="download(file)"
                    >
                    {{ $t('button.downloadExtraFile') }}
                  </v-btn>
                </v-row>
                <v-row>
                  <v-container
                      v-if="file.comment"
                      id="scroll-target"
                      class="overflow-y-auto mx-auto mt-2"
                      style="height: 100px; max-width:100%;  border: #000 1px solid; "
                      wrap
                    >
                      <v-row
                        v-for="(message, index) in splitMessageLine(file.comment)"
                        :key="index"
                        class="l-3 my-0 mx-auto"
                        style="max-width:100%;"
                      >
                        {{ message }}
                      </v-row>
                    </v-container>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <v-row class="my-12">
          <v-col style="text-align:center">
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              href="/tos"
              width="30vw"
              max-width="170px"
              color="back"
              style="font-size:20px"
              dark
              rounded
              >{{ $t('button.back') }}</v-btn
            >
          </v-col>
          <v-col style="text-align:center">
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              @click="goNextPage"
              width="30vw"
              max-width="170px"
              color="next"
              style="font-size:20px"
              dark
              rounded
              >{{ $t('button.next') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import {
  getBaseInfo,
  getExtraPictureList,
  getExtraPicture,
} from '@/apis/contractor/contracts';
import { mapActions, mapGetters } from 'vuex';
import { splitMessageLine } from '@/lib/commonUtil';
import { ExtensionsAllowedInExtraPicture } from '@/lib/const';
import { downloader } from '@/lib/commonUtil';

export default {
  props: {
    stepPoint: Number,
  },
  data: () => ({
    comment: [],
    extraFiles: [],
    notImageFiles: [],
  }),
  methods: {
    goNextPage() {
      const nextStep = this.stepPoint + 1;
      if (this.pendingStep() < nextStep) this.setPendingStep(nextStep);

      this.$router.push({
        path: '/review_contract/1',
      });
    },
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    async download(file) {
      const blob = await getExtraPicture(file.id);
      downloader(blob, file.name);
    },
    ...mapGetters('contract', ['pendingStep']),
    ...mapActions('contract', ['setPendingStep']),
  },
  async mounted() {
    const response = await getBaseInfo();
    if (response instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (response) {
      const comment = response.comment;
      if (comment && comment.length > 0) {
        this.comment = splitMessageLine(comment);
      }
    }
    const files = [];
    this.notImageFiles = [];
    const url = window.URL || window.webkitURL;
    const extraPictureList = await getExtraPictureList();
    for (const data of extraPictureList) {
      let extension = data.name.slice(
        ((data.name.lastIndexOf('.') - 1) >>> 0) + 2
      );
      if (ExtensionsAllowedInExtraPicture.includes(extension)) {
        const blob = await getExtraPicture(data.id);
        if (blob) {
          const src = url.createObjectURL(blob);
          const img = new Image();
          img.onload = () => {
            files.push({
              src: src,
              comment: splitMessageLine(data.comment),
              width: img.width,
              height: img.height,
              name: data.name,
            });
          };
          img.src = src;
        }
      } else {
        this.notImageFiles.push(data);
      }
    }
    this.extraFiles = files;
  },
};
</script>
