var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            {
              staticClass: "mx-auto",
              staticStyle: { width: "90%", "max-height": "90vh" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "font-weight-bold pa-2",
                      staticStyle: { width: "100%" },
                      attrs: { outlined: "" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("title.contractor.siteUsageGuide")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pl-4 pr-4" },
                [
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("description.siteUsageGuides.explanation1")
                        )
                      ),
                    ]),
                  ]),
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("description.siteUsageGuides.explanation2")
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "font-weight-bold pa-2",
                      staticStyle: { width: "100%" },
                      attrs: { outlined: "" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "title.contractor.applicationConfirmationMethod"
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pl-4 pr-4" },
                [
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "description.applicationConfirmationMethods.explanation1"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "description.applicationConfirmationMethods.explanation2"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "description.applicationConfirmationMethods.explanation3"
                          )
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.comment.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "font-weight-bold pa-2",
                              staticStyle: { width: "100%" },
                              attrs: { outlined: "" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("label.agencyComment")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pl-4 pr-4" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "ma-0 pa-0" },
                            _vm._l(_vm.comment, function (message, index) {
                              return _c(
                                "p",
                                {
                                  key: index,
                                  staticClass: "ma-0 pa-0",
                                  staticStyle: { "word-wrap": "break-word" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(message) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.extraFiles.length > 0 || _vm.notImageFiles.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "pt-4" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "font-weight-bold pa-2",
                              staticStyle: { width: "100%" },
                              attrs: { outlined: "" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("label.referenceMaterial")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pl-4 pr-4" },
                        _vm._l(_vm.extraFiles, function (file) {
                          return _c(
                            "v-col",
                            { key: file.src },
                            [
                              _c(
                                "v-container",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-width": "400px" },
                                },
                                [
                                  _c("v-row", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "float-left mr-3 my-1 pa-0",
                                      },
                                      [_vm._v("●" + _vm._s(file.name))]
                                    ),
                                  ]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticStyle: {
                                            width: "400px",
                                            height: "300px",
                                            "max-width": "90vw",
                                            border: "#ddd 3px solid",
                                          },
                                          attrs: { "justify-center": "" },
                                        },
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto my-auto",
                                            staticStyle: { display: "block" },
                                            attrs: {
                                              "max-width": "100%",
                                              "max-height": "100%",
                                              contain: true,
                                              src: file.src,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "font-weight-black",
                                          staticStyle: { "font-size": "14px" },
                                          attrs: {
                                            align: "center",
                                            href: file.src,
                                            width: "35vw",
                                            "max-width": "170px",
                                            color: "download",
                                            download: file.name,
                                            dark: "",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "button.downloadExtraFile"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      file.comment
                                        ? _c(
                                            "v-container",
                                            {
                                              staticClass:
                                                "overflow-y-auto mx-auto mt-2",
                                              staticStyle: {
                                                height: "100px",
                                                width: "400px",
                                                "max-width": "100%",
                                                border: "#000 1px solid",
                                              },
                                              attrs: {
                                                id: "scroll-target",
                                                wrap: "",
                                              },
                                            },
                                            _vm._l(
                                              file.comment,
                                              function (message, index) {
                                                return _c(
                                                  "v-row",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "l-3 my-0 mx-auto",
                                                    staticStyle: {
                                                      "max-width": "400px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(message) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pl-4 pr-4" },
                        _vm._l(_vm.notImageFiles, function (file) {
                          return _c(
                            "v-col",
                            { key: file.id },
                            [
                              _c(
                                "v-container",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-width": "400px" },
                                },
                                [
                                  _c("v-row", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "float-left mr-3 my-1 pa-0",
                                      },
                                      [_vm._v("●" + _vm._s(file.name))]
                                    ),
                                  ]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "font-weight-black",
                                          staticStyle: { "font-size": "14px" },
                                          attrs: {
                                            align: "center",
                                            width: "35vw",
                                            "max-width": "170px",
                                            color: "download",
                                            dark: "",
                                            rounded: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.download(file)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "button.downloadExtraFile"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      file.comment
                                        ? _c(
                                            "v-container",
                                            {
                                              staticClass:
                                                "overflow-y-auto mx-auto mt-2",
                                              staticStyle: {
                                                height: "100px",
                                                "max-width": "100%",
                                                border: "#000 1px solid",
                                              },
                                              attrs: {
                                                id: "scroll-target",
                                                wrap: "",
                                              },
                                            },
                                            _vm._l(
                                              _vm.splitMessageLine(
                                                file.comment
                                              ),
                                              function (message, index) {
                                                return _c(
                                                  "v-row",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "l-3 my-0 mx-auto",
                                                    staticStyle: {
                                                      "max-width": "100%",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(message) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-12" },
                [
                  _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            href: "/tos",
                            width: "30vw",
                            "max-width": "170px",
                            color: "back",
                            dark: "",
                            rounded: "",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.back")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            width: "30vw",
                            "max-width": "170px",
                            color: "next",
                            dark: "",
                            rounded: "",
                          },
                          on: { click: _vm.goNextPage },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.next")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }